import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a
              href="/poradnik/katar/skutki_niewyleczonego_kataru/"
              className="sel"
            >
              {" "}
              &gt; Skutki niewyleczonego kataru
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_754645981.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Skutki niewyleczonego kataru</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <b>Być</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>może zdarzyło Ci się</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>kiedyś</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>pójść</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>
                      do pracy z kłuciem w gardle, katarem lub silnym bólem
                      zatok i kilka razy pokonać
                    </b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>pierwsze objawy choroby, zanim zdążyła się</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>
                      ona rozwinąć. Jednak kolejny atak wirusów może przerodzić
                    </b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>się</b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>
                      w poważne schorzenie. Co Ci może wówczas grozić i jakie są
                    </b>
                  </span>
                  <span className="s2">
                    <b> </b>
                  </span>
                  <span className="s1">
                    <b>skutki niewyleczonego kataru?</b>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Katar infekcyjny jest jednym z objawów towarzyszącym{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębieniu
                    </a>{" "}
                    lub grypie. Przegrzanie lub przewianie organizmu skutkuje
                    obniżeniem odporności, a w konsekwencji może prowadzić do
                    ataku wirusów i rozwoju infekcji. Przyczyną
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">pojawienia się</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    <a href="http://niezyt_nosa" target="_blank">
                      nieżytu nosa
                    </a>{" "}
                    może być
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    także przebywanie w tym samym pomieszczeniu, co przeziębiona
                    osoba. Gdy nasz organizm zostanie
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    zainfekowany przez wirusy, już podczas pierwszych 3-4 dni
                    choroby możemy dostrzec takie symptomy jak: zablokowany nos,
                    zalegająca{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzielina
                    </a>
                    , ból głowy, ucisk w zatokach czy też gorączka. Zwykle w
                    takiej sytuacji zaopatrujemy się
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">w dużą</span>
                  <span className="s2"> </span>
                  <span className="s1">dawkę</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    leków przeciwbólowych, środków zbijających gorączkę, które
                    pomagają, ale jedynie doraźnie. W wyniku ich działania część
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    dolegliwości ustępuje, podwyższona temperatura spada, a my
                    czujemy się
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">lepiej. Taki efekt uspokaja, gdyż</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    wydaje nam się, że zmagania z infekcją
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    zostały zakończone. Tymczasem powikłania po przebytym
                    przeziębieniu mogą
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">być</span>
                  <span className="s2"> </span>
                  <span className="s1">naprawdę</span>
                  <span className="s2"> </span>
                  <span className="s1">poważne.&nbsp;</span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Niewyleczony katar może prowadzić do przeziębienie i grypy."
                    src="/web/kcfinder/uploads/images/shutterstock_519509851.jpg"
                    style={{
                      width: "380px",
                      height: "253px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">Wiosna i jesień</span>
                  <span className="s2"> </span>
                  <span className="s1">to momenty, kiedy zachorowalność</span>
                  <span className="s2"> </span>
                  <span className="s1">na przeziębienie i grypę</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    gwałtownie wzrasta, a gabinety lekarskie przeżywają
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    prawdziwe oblężenie. Katar często bywa lekceważony i
                    nieleczony, w następstwie czego może prowadzić do wielu
                    powikłań (np. zapalenie ucha środkowego,{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      zapalenia zatok
                    </a>
                    &nbsp;czy zapalenia oskrzeli.)&nbsp; Dzieje się
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    tak, ponieważ zablokowany nos i zalegająca wydzielina
                    powodują, że oddychamy przez usta, a do organizmu trafia
                    nieogrzane i nieoczyszczone powietrze (tylko nos posiada
                    rzęski, które oczyszczają powietrze i chronią przed
                    przedostaniem się drobnoustrojów). Zalegająca wydzielina
                    może ulec nadkażeniu bakteryjnemu, co w konsekwencji może
                    powodować zapalenie zatok, ucha środkowego, spojówek,
                    zapalenie płuc i krtani.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Zapalenie ucha środkowego to bardzo częste powikłanie po
                    przebytym przeziębieniu, w szczególności u dzieci. Jego
                    typowymi objawami są: ból uszu, niedosłuch, brak apetytu,
                    czasem wyciek ropnej wydzieliny z ucha i zawroty głowy.
                    Pojawiający się stan zapalny osłabia{" "}
                    <a href="/slowniczek/#uklad_immunologiczny" target="_blank">
                      układ immunologiczny
                    </a>
                    &nbsp;i wywołuje stan podgorączkowy. Dlatego w czasie kataru
                    należy pamiętać o odpowiedniej higienie nosa oraz stosowaniu
                    leków odblokowujących nos.&nbsp; Pomocne będą preparaty
                    marki Sudafed®.{" "}
                    <a href="/produkty/sudafed_tabletki/" target="_blank">
                      Sudafed® Tabletki
                    </a>
                    ,&nbsp;które dzięki zawartości{" "}
                    <a href="/slowniczek/#pseudoefedryna" target="_blank">
                      pseudoefedryny
                    </a>
                    , zmniejszają obrzęk błony śluzowej nosa i ilość wydzieliny.
                    Aerozole{" "}
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Sudafed
                    </a>
                  </span>
                  <a href="/produkty/sudafed_xylospray/" target="_blank">
                    <span className="s3">
                      <sup>® </sup>
                    </span>
                  </a>
                  <span className="s4">
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Xylospray
                    </a>{" "}
                    lub{" "}
                  </span>
                  <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                    <span className="s1">Sudafed</span>
                    <span className="s3">
                      <sup>®</sup>
                    </span>
                    <span className="s1"> </span>
                  </a>
                  <span className="s4">
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Xylospray HA
                    </a>
                    ,&nbsp;które dzięki zawartości{" "}
                    <a href="/slowniczek/#ksylometazolina" target="_blank">
                      ksylometazoliny
                    </a>
                    , szybko odblokowują nos.&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Podczas sezonu przeziębieniowego szczególną
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">uwagę</span>
                  <span className="s2"> </span>
                  <span className="s1">należy poświęcić</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    małym dzieciom. Ich układy odpornościowe nie są
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    w pełni ukształtowane, dlatego kilkulatkowie częściej
                    chorują
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">i są</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    bardziej narażeni na występowanie powikłań. Małe dzieci nie
                    potrafią
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">też</span>
                  <span className="s2"> </span>
                  <span className="s1">samodzielnie wydmuchać</span>
                  <span className="s2"> </span>
                  <span className="s1">nosa czy zaaplikować</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    sobie lekarstw. Co istotne, gdy dziecko się
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    rodzi, jego{" "}
                    <a href="/slowniczek/#trabka_sluchowa" target="_blank">
                      trąbka słuchowa
                    </a>
                    , łącząca ucho środkowe z górną
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">częścią</span>
                  <span className="s2"> </span>
                  <span className="s1">gardła (zwana trąbką</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    Eustachiusza), jest wyjątkowo szeroka, cały czas otwarta i
                    położona bardziej poziomo, niż
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">u osób dorosłych.</span>
                  <span className="s1">
                    &nbsp;Taki układ powoduje, że częstym powikłaniem po katarze
                    jest zapalenie ucha środkowego.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Aby zmniejszyć ryzyko rozwoju infekcji wirusowej i
                    wystąpienia powikłań, warto podczas kataru stosować
                    preparaty odblokowujące nos oraz ułatwiające usunięcie
                    wydzieliny. Dobrym rozwiązaniem mogą
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">być</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    także inhalacje oraz użycie aspiratora, który pozwoli usunąć
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    zalegający śluz. W walce z objawami infekcji może też
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">
                    pomóc{" "}
                    <a href="/produkty/sudafed_xylospray_dla_dzieci/">
                      Sudafed<sup>®</sup> XyloSpray
                    </a>
                    &nbsp;lub&nbsp;
                    <a href="/produkty/sudafed_xylospray_ha_dla_dzieci/">
                      Sudafed
                    </a>
                  </span>
                  <a href="/produkty/sudafed_xylospray_ha_dla_dzieci/">
                    <sup>®</sup>&nbsp;
                  </a>
                  <span className="s1">
                    <a href="/produkty/sudafed_xylospray_ha_dla_dzieci/">
                      Xylospray HA
                    </a>
                    &nbsp;dla dzieci, który obkurcza błonę
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">śluzową, pomaga pozbyć się</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    zalegającej wydzieliny i ułatwia oddychanie podczas kataru.
                    Atomizer rozpyla delikatną mgiełkę nie powodując dyskomfortu
                    podczas aplikacji. Produkt podany przed snem zapewnia
                    spokojną
                  </span>
                  <span className="s2"> </span>
                  <span className="s1">i przespaną</span>
                  <span className="s2"> </span>
                  <span className="s1">
                    noc, a zawarty w nim sorbitol ma działanie
                    nawilżające.&nbsp;&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Emeryk Andrzej, Rapiejko Piotr, Lipiec Agnieszka,{" "}
                        <i>Alergiczny nieżyt nosa –</i>
                      </span>
                      <span className="s2">
                        <i> </i>
                      </span>
                      <span className="s1">
                        <i>kompendium dla lekarzy</i>, Termedia, 2013.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        <i>
                          Grypa i przeziębienie. Porady lekarza rodzinnego,{" "}
                        </i>
                        opracowanie zbiorowe, Literat, 2012.
                      </span>
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a href="/poradnik/katar/poznaj_rodzaje_kataru" className="box">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="title">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="title">Podejmij szybką walkę z katarem</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
